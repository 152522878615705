import styled from "styled-components";

type Props = {
  progress: number;
  isActive: boolean;
};

export const IndicatorContainer = styled.div`
  width: 100vw;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: sticky;
  top: 25rem;
  gap: 10px;
  z-index: 10;
  @media (max-width: 30em) {
    top: 16.5rem;
  }
`;

export const IndicatorBase = styled.div<Props>.attrs(({ isActive}: Props) => {
  const boxShadow = isActive
    ? "0px 0px 10px 2px rgba(250,250,200,0.7)"
    : "0px 0px 5px 1px rgba(20,20,20,0.7)";
  const backgroundColor = isActive
    ? "rgba(210,210,210,0.8)"
    : "rgba(220,240,240,0.9)";
  return {
    style: {
      boxShadow,
      backgroundColor,
      transition: "background-color 1s ease",
    }
  } as unknown as Props;
})`
  border-radius: 1px;
  display: flex;
  width: 2rem;
  height: 0.188rem;
  z-index: 10;
  background-color: rgba(200, 200, 200, 0.7);
`;

export const IndicatorBar = styled.div<Props>.attrs(({ isActive, progress }: Props) => {
  const width = isActive ? `${progress * 100}%` : "0vw";

  const background = isActive ? "linear-gradient(90deg, rgba(230,30,10,1) 0%, rgba(250,50,50,1) 100%)" : "rgb(97,104,246)";
  const transition = isActive ? "width 0.03s ease" : "";
  return { style: { width, background, transition }} as unknown as Props;
  })`
  height: 0.188rem;
  `;
