import styled from 'styled-components'

export const Mensalidade = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;

    .disclaimer {
      bottom: 75px;
      color: var(--secondary-text);
      font-family: Ubuntu;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      position: absolute;
      max-width: 100%;
      width: 591px;

      @media (max-width: 520px) {
        left: 50px;
        right: 50px;
        width: auto;
      }
    }

    h1{
        color: var(--secondary-text);
        font-family: 'ubuntu';
        font-weight: bold;
        font-size: clamp(2rem, 3.665vw, 3.125rem);
        line-height: 57px;
        width: min(43.27vw,36.942rem);
        order: 1;
        @media(max-width: 48em) {
            font-size: 24px;
            line-height: normal;
            width: 85%;
            text-align: left !important;
            transform: translateY(-250px);
        }
    }
    .col-md-6 > p {
        width: min(43.27vw, 36.942rem);
        margin-top: 1rem;
        color: var(--secondary-text);
        font-family: 'ubuntu';
        font-weight: 400;
        font-size: min(2vw, 1.25rem);
        line-height: 24px;
        order: 2;
        @media(max-width: 48em) {color: #FFF;
            font-size: 16px;
            line-height: normal;
            text-align: left !important;
            width: 85%;
            transform: translateY(-250px);
            margin-bottom: -225px;
        }
    }
    .col-md-6 > picture > img {
        object-fit: none;
        position: relative;
    }
    .subBt {
            order: 4;
            button {
            min-width: 21.875rem;
            font-size: 1.25rem;
            margin-top: 3.125rem;
            text-transform: uppercase;
            padding: 21px 0;
            margin-top: 100px;
        }
        @media(max-width: 80rem) {
            display: flex;
            width: 100%;
            text-decoration: none;
            button {
                min-width: 18.813rem;
            }
        }
        @media (max-width: 520px) {
          margin-top: 64px;
        }
    }
    .container {
        max-width: 100%;
        min-height: 56.25rem;
        position: relative;
        padding: 0;
        picture {
                order: 5;
            img {
                position: absolute;
                object-fit: cover;
                object-position: center;
                top: 0;
                left: 0;
            }
        }
        @media(max-width: 48em) {
            min-height: 17.125rem;
            picture {
                order: 3;
                img {
                    position: relative;
                    display: block;
                    object-fit: contain;
                    height: auto;
                    top: 2rem;
                }
            }
            div.row {
                background-color: var(--primary);
            }
        }
        .col-md-6 {
            display: flex;
            flex-direction: column;
            width: 100%;
            picture {
                position: absolute;
                left: max(48rem, 50vw);
                top: 18rem;
                @media (max-width: 80em) {
                    position: relative;
                    top: 0;
                    left: 0;
                }
                img {
                    object-fit: contain;
                    width: clamp(23.5rem, 44.3632vw, 37.876rem);
                    position: relative;
                    height: auto;
                    margin: 0 auto;
                }
            }
            @media(max-width: 80em) {
                align-items: center;
            }
        }
        div.row {
            order: 3;
            position: relative;
            justify-content: space-between;
            .row {
                gap: 30px;
                justify-content: flex-start;
                margin-top: 3rem;
                padding-right: 2.625rem;
                margin-left: 0;
                height: 13.625rem;
                max-width: 43.75rem;
                width: 50%;
            }
            @media (max-width: 80rem) {
                > .col-md-6 {
                    margin-left: 0;
                    margin-right: 0;
                    margin-top: 0;
                    height: 100%;
                }
                div.row {
                    height: 31.25rem;
                    padding-right: 0;
                    max-width: 100%;
                    width: 100%;
                    flex-direction: column;
                    flex-wrap: nowrap;
                }
            justify-content: center;
            align-items: center;
            h1, p {
                text-align: center;
            }
            }
        }
        .row:nth-child(2) {
            padding-top: 12.5rem;
            padding-left: 140px;
            @media (max-width: 80rem) {
                padding-left: 0;
                padding-top: 20rem;
                height: 100%;
            }
            @media (max-width: 48rem) {
                padding-left: 0;
                padding-top: 0;
                height: 100%;
            }
        }
    }
    img{
        width: 100%;
        height: 100%;
    }
    fieldset {
        border: 7px solid var(--mensalidade-border-box);
        padding: 10px;
        border-radius: 14px;
        legend {
            align-items: center;
            display: flex;
            height: 47px;
            justify-content: center;
            width: 15ch;
            margin: 0 auto;
            text-transform: uppercase;
            float: none;
            color: var(--secondary-text);
            font-family: 'ubuntu';
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            span {
                width: 11ch;
                margin: 0 auto;
            }
        }
        @media (max-width: 80rem) {
            margin-top: 3.5rem;
        }
    }
    .preco{
        position: relative;
        min-height: 13.625rem;
        max-width: 17rem;
        background-position: center;
        h2{
            position: absolute;
            top: 0;
            background: transparent;
            color: var(--secondary-text);
            font-family: 'ubuntu';
            font-weight: bold;
            font-size: 20px;
            line-height: 1;
            margin:-10px 0 0;
            padding: 0 3px;
            text-transform: uppercase;
            position: relative;
            float: left;
            bottom: 0.625rem;
            text-align: center;
        }
        .consultas{
            display: flex;
            justify-content: space-evenly;
            margin-top: 20px;
            h3{
                color: var(--secondary-text);
                font-family: 'ubuntu';
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                min-width: 94px;
            }
            p{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 0.625rem;
                color: var(--secondary-text);
                font-family: 'ubuntu';
                font-weight: bold;
                font-size: 1.688rem;
                line-height: 1.938rem;
                position: relative;
                bottom: 0.813rem;
                .currency-unit {
                    margin-top: 0.5rem;
                    align-self: flex-start;
                    font-size: 1.688rem;
                    line-height: 1.938rem;
                }
                span{
                    display: inline;
                    font-size: 3.125rem;
                    line-height: 3.125rem;
                }
            }
        }
    }
    .centro{
        max-width: 15rem;
        text-align: center;
        p{
            color: var(--secondary-text);
            font-family: 'ubuntu';
            font-weight: bold;
            font-size: 39px;
            line-height: 44px;
            margin-top: 1.125rem;
            .preco-g{
                font-size: 76px;
                position: relative;
                top: 10px;
            }
            .preco-centavo{
                font-size: 29px;
            }
        }
        h3{
                color: var(--secondary-text);
                font-family: 'ubuntu';
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                margin-top: 25px;
            }
    }
    @media (max-width: 48rem) {
        margin-top: -5rem;
    }
`