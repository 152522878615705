import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import Parceiros from "./pages/Parceiros";
import Beneficios from "./pages/Beneficios";
import Contato from "./pages/Contato";
import { ContactFormProvider } from "./context/ContactFormDataContext";
import './App.css'
import { GlobalStateProvider, useGlobalState } from "./context/GlobalStateContext";
import { useEffect } from "react";
import { getPricesCdt } from "./services/prices-cartao-todos.service";

function App() {
  const { state, setCdtPrices } = useGlobalState();
  useEffect(() => {
    async function PriceCdt() {
      const response = await getPricesCdt();
      console.log(response);
      setCdtPrices(response);
    }
    PriceCdt();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/parceiros" element={<Parceiros />} />
        <Route path="/beneficios" element={<Beneficios />} />
        <Route
          path="/contato"
          element={
            <ContactFormProvider>
              <Contato />
            </ContactFormProvider>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
