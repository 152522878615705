import React, {
  useContext,
  TextareaHTMLAttributes,
  ChangeEventHandler,
} from "react";
import {
  ContactFormContext,
  IFormData,
} from "../../context/ContactFormDataContext";
import useValidInputCtx from "../../hooks/useValidInputCtx";
import * as S from "./style";

export interface InputProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: keyof IFormData["userInputs"];
}

function CustomTextArea({ name, ...customParam }: InputProps) {
  const { formData } = useContext(ContactFormContext);
  const {
    inputParams: { onChange, onFocus, onBlur },
    error,
  } = useValidInputCtx(name);

  return (
    <>
      <S.TextArea
        className={error ? "input-error" : ""}
        name={name}
        cols={30}
        rows={5}
        onFocus={onFocus as unknown as ChangeEventHandler}
        onChange={onChange as unknown as ChangeEventHandler}
        onBlur={onBlur as unknown as ChangeEventHandler}
        value={formData.userInputs[name]}
        placeholder="Sua mensagem"
        {...customParam}
      />
      {error && <span className="error-icon">!</span>}
    </>
  );
}

export default CustomTextArea;
