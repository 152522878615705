import { Link } from "react-router-dom";
import styled from "styled-components";

type IProps = {
    translucent: boolean;
}

const HeaderBase = styled.div`
    overflow: hidden;
    padding: 20px 0;
    position: fixed;
    width: 100%;
    z-index: 9999999999999;
    .grid{
        display: flex;
        justify-content: space-between;
    }
    .grid > div:nth-child(2) {
            width: 100%;
            display: flex;
            align-items: flex-end;
            margin-left: 0.625rem;
            @media(max-width: 71.25rem) {
                margin-left: 0;
            }
    }
    .desk{
        display: flex;
        padding-left: clamp(3rem, 21.5vw, 12.5rem);
        justify-content: flex-end;
        gap: 20px;
        width: 100%;
        align-items: baseline;
        a{
            text-decoration: none;
            p{
                font-family: "Ubuntu", sans-serif;
                font-weight: bold;
                color: #fff;
                font-size: 0.875rem;
                line-height: 1rem;
                padding-top: 7px;
            }
        }
        a.btn-desk{
            button{
                width: 15.375rem;
                font-size: 1.25rem;
                height: 2.75rem;
            }
        }
    }
    .header-container {
        max-width: 71.25rem;
        margin: 0 auto;
        @media (max-width: 72rem) {
            padding: 0 1.25rem;
        }
    }
    @media (max-width: 65rem) {
        .desk {
            padding-left: clamp(3rem, 7vw, 8rem);
            justify-content: space-evenly;
            a.btn-desk{
                button{
                    width: 10.375rem;
                    font-size: 1rem;
                    height: 2.75rem;
                }
            }
        }
    }
    @media (max-width: 48rem){
        position: fixed;
        a > img {
           width: 9.375rem;
        }
        .bt-mobile > img {
            margin-top: 0.25rem;
        }
        .mobile {
            display: flex;
            justify-content: center;
            @media (max-width: 30em) {
                justify-content: flex-end;
            }
        }
        .desk{
            display: none;
        }
    }
    @media (min-width: 48rem){
        .mobile{
            display: none;
        }
    }
    .aberto{
        transform: translateX(0);
    }
`

export const Header = styled(HeaderBase)<IProps>.attrs<IProps>(({ translucent }: IProps) => {
    const backgroundSelected = translucent ? "rgba(218,56,50,0)" : "rgba(218,56,50,1)";
    const position = translucent ? "absolute" : "fixed";

    return {
        style: {
            backgroundColor: backgroundSelected,
            position: position,
            transition: "background-color 0.5s ease",
        }
    } as unknown as IProps;
})<IProps>``;

export const HeaderLink = styled(Link)`
    transition: .3s ease-in-out;
    &:hover {
        border-bottom: 10px solid #fdc800;
    }
`

export const Modal = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;
    background: #DA3832;
    width: calc(100vw - 74px);
    height: 100vh;
    transform: translateX(calc(100% + 74px));
    transition: .3s ease-in-out;
    img{
        position: absolute;
        right: 40px;
        top: 40px;
    }
    .bts{
        display: block;
        padding: 0;
        padding-top: clamp(2rem, 15vmin, 10rem);
        text-align: center;
        a{
            text-decoration: none;
            p{
                font-family: 'ubuntu';
                font-weight: bold;
                color: #F6D617;
                font-size: 1.375rem;
                line-height: 1.625rem;
                text-transform: uppercase;
                padding: 1.5rem 0;
            }
        }
        button{
            min-width: 16.875rem;
            min-height: 3rem;
            background-color: #F6D617;
            font-family: 'ubuntu';
            font-weight: bold;
            font-size: 22px;
            color: #000;
            margin-top: 80px;
            border: none;
            border-radius: 30px;
            @media (max-height: 26.25rem) {
                margin-top: 1.25rem;
            }
        }
    }
`;