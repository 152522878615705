import styled from "styled-components";

export const Button = styled.a`
    line-height: normal !important;
    margin-top: -12px;
    button {
        margin-right: 0;
        padding-top: 4px;
        padding-bottom: 4px;
    }
`

export const Footer = styled.div`
    background-color: #DA3832;
    padding-top: 50px;
    text-align: center;
    p{
        font-family: 'ubuntu';
        font-weight: 300;
        color: #fff;
        font-size: 15px;
        line-height: 17px;
        @media (max-width: 768px){
            margin-bottom: 5px;
            margin-top: 15px;
        }
    }
    a{
        font-family: 'ubuntu';
        font-weight: bold;
        color: #fff;
        font-size: 34px;
        line-height: 39px;
        text-decoration: none;
    }
    .social{
        padding-top: 30px;
        img{
            margin: 0px 5px;
        }
    }
    .txt-footer{
        border-bottom: solid 1px #fff;
        margin-top: 30px;
        padding: 15px 0;
        p{
            text-align: center;
            font-family: 'Ubuntu', sans-serif;
            font-weight: 100;
            color: #fff;
            font-size: 0.813rem;
            line-height: 20px;
        }
        @media (max-width: 768px){
            padding: 15px;
            width: 95%;
            margin: 0 auto;
        }
    }
    .credits{
        display: flex;
        justify-content: center;
        padding: 25px 0;
        position: relative;
        @media (max-width: 768px){
            p{
                margin-top: 0;
            }
        }
        button{
            max-width: 200px;
            font-family: 'ubuntu';
            font-weight: bold;
            font-size: 14px;
            border-radius: 30px;
            position: relative;
            bottom: 6px;
            margin-left: 10px;
        }
        img{
            position: absolute;
            right: 20px;
        }
    }
`;